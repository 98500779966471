// extracted by mini-css-extract-plugin
export var arrow = "phonepe-module--arrow---nNDS";
export var block = "phonepe-module--block--b7ZFj";
export var blockTitle = "phonepe-module--blockTitle--50Mkm";
export var caption = "phonepe-module--caption--v4T19";
export var divider = "phonepe-module--divider--9rzHA";
export var floatingBar = "phonepe-module--floatingBar---5z7v";
export var floatingBarContent = "phonepe-module--floatingBarContent--ltvqA";
export var floatingBarText = "phonepe-module--floatingBarText--EkxTH";
export var footer = "phonepe-module--footer--OlcTf";
export var hero = "phonepe-module--hero--u1adh";
export var heroTitle = "phonepe-module--heroTitle--X5ENU";
export var highlights = "phonepe-module--highlights--3c23b";
export var joinDetails = "phonepe-module--joinDetails--ZjS-c";
export var preFooter = "phonepe-module--preFooter--Df6Oq";
export var schedule = "phonepe-module--schedule--ScuSW";
export var scheduleContent = "phonepe-module--scheduleContent--wGFuf";
export var scheduleContentCaption = "phonepe-module--scheduleContentCaption--Bt1uL";
export var scheduleContentTitle = "phonepe-module--scheduleContentTitle--i+etD";
export var subCaption = "phonepe-module--subCaption--jTRhi";